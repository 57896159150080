<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
                    <v-col md="2" sm="6" cols="12">
                      <h3>ประเภทคลังสินค้า</h3>
                      <v-radio-group
                        @change="getProductWornOut"
                        v-model="type_product" hide-details>
                        <v-radio key="online" label="ออนไลน์" value="1"></v-radio>
                        <v-radio key="offline" label="ภายใน" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>

<!--          <v-col md="2" sm="6" cols="12">-->
<!--            <h3 v-if="type_product == 2">สินค้าเสีย</h3>-->
<!--            <v-checkbox -->
<!--              v-if="type_product == 2"-->
<!--              title="ของเสียหาย"-->
<!--              @change="getProductWornOut"-->
<!--              v-model="is_worn_out"-->
<!--              value="true"-->
<!--              label="ใช่"-->
<!--            ></v-checkbox>-->
<!--          </v-col>-->

          <v-col md="4" sm="12" cols="12">
            <h3>เลือกช่วงเวลา</h3>
            <DatePicker
              type="date"
              range
              multi-calendars
              value-type="format"
              format="YYYY-MM-DD"
              placeholder="ช่วงเวลา"
              @change="getProductWornOut"
              v-model="date"
            ></DatePicker>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <h3>ค้นหา</h3>
            <v-text-field
              v-model="search"
              hide-details
              dense
              @change="getProductWornOut"
              placeholder="คีย์เวิร์ด"
            ></v-text-field>
          </v-col>
          <v-col md="2" sm="6" cols="12">
<!--           <v-btn-->
<!--             @click="exportFile"-->
<!--             class="mt-4"-->
<!--             color="primary">Export</v-btn>-->
            <div class="text-center wapperContentBottom"  style="width: 150px;">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <div class="wapperIconContent">
                    <v-btn title="Export" color="secondary" v-bind="attrs"
                           hide-details
                           v-on="on">
                      <v-icon class="me-1">
                        {{ icons.mdiPlus }}
                      </v-icon>
                      Export
                    </v-btn>
                  </div>
                </template>
                <v-list style="height: 140px;">
                  <v-list-item style="display: block">
                    <v-list-item-title>
                      <v-dialog transition="dialog-top-transition" width="1000">
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <!--                                :on="index == 1 ? 'on' : ''"-->
<!--                            @click="uploadLink(index)"-->
                            <v-btn
                              @click="exportFile(1)"
                              text v-bind="attrs" class="mt-5 testdialog">
                              <v-icon class="me-1">
                                {{ icons.mdiPlus }}
                              </v-icon>
                              PDF
                            </v-btn>
                          </div>
                        </template>
                      </v-dialog>
                    </v-list-item-title>
                    <v-list-item-title>
                      <v-dialog transition="dialog-top-transition" width="1000">
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <!--                                :on="index == 1 ? 'on' : ''"-->
<!--                            @click="uploadLink(index)"-->
                            <v-btn
                              @click="exportFile(2)"
                              text v-bind="attrs" class="mt-5 testdialog">
                              <v-icon class="me-1">
                                {{ icons.mdiPlus }}
                              </v-icon>
                              CSV
                            </v-btn>
                          </div>
                        </template>
                      </v-dialog>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!--                  <div class="mt-10">-->
              <!--                    <v-btn-->
              <!--                      style="float:right;"-->
              <!--                      v-if="($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id"-->
              <!--                      color="primary" @click="validateForm()">-->
              <!--                      บันทึกข้อมูล-->
              <!--                    </v-btn>-->
              <!--                  </div>-->
            </div>


            <!--                <v-row style="margin-top: 10px" v-if="$store.state.orderData.length > 0">-->
            <!--                  <v-col md="12" sm="12" cols="12" class="d-flex align-center">-->
            <!--                    <h3> รายละเอียดเพิ่มเติม</h3>-->
            <!--                  </v-col>-->
            <!--                  <v-col md="12" sm="12" cols="12">-->
            <!--                    <v-textarea-->
            <!--                      v-model="$store.state.orderDetil.order_detail_oth"-->
            <!--                      placeholder="รายละเอียดเพิ่มเติม"-->
            <!--                    ></v-textarea>-->
            <!--                    <div style="color: red;">-->
            <!--                      *เช่น ชื่อลูกค้าในเฟส หรือ ชื่อตัวแทน หรือเลขอ้างอิงการขายของท่าน-->
            <!--                      หากไม่ได้ส่งไปรษณีย์ ให้ระบบวันมารับที่ร้านที่ช่องนี้-->
            <!--                    </div>-->
            <!--                  </v-col>-->
            <!--                </v-row>-->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="product_worn_outs"
      :search="search"
      :server-items-length="total"
      :options.sync="serverOptions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="elevation-1"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-toolbar flat>
          <v-dialog
            v-model="dialogDelete"
            max-width="600px"
          >
            <v-card>
              <v-card-title class="text-h5">
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  outlined
                  @click="closeDelete"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="deleteItemConfirm"
                >
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.index="{ index }">
        {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
      </template>
      <template v-slot:item.total_cost="{ item }">
        {{ item.product_costs_present * item.product_amount}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="deleteItem(item)"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
      <template v-slot:no-data>
        <!-- <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mdiPencil, mdiDelete,mdiPlus} from '@mdi/js'
import DatePicker from 'vue2-datepicker'

// import { mdiBank, mdiPlus, mdiLock, mdiLockOpenVariant } from '@mdi/js'

// import config from '../../../config'
import instance from '../../../axios.service'
import instance_product_worn_out from '../../../services/productWornOut'
import Swal from "sweetalert2";
import instance_stock_employee from "@/services/stockEmokoyee";

export default {
  components: {
    DatePicker
  },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiPlus,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    product_worn_outs: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    date: [],
    type_product: "2",
    is_worn_out: 'true',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'ชื่อสินค้า', value: 'product.product_name', sortable: false},
      {text: 'รหัส', value: 'product.product_code'},
      {text: 'จำนวน', value: 'product_amount'},
      {text: 'ทุน', value: 'product_costs_present'},
      {text: 'ทุน เพิ่ม/ลด(บาท)', value: 'total_cost'},
      {text: 'วัน/เวลา', value: 'insert_date'},
//       <td>@{{product.insert_date}} @{{product.insert_time}} น.</td>
      {text: 'พนักงาน', value: 'employee.employee_name'},
      {text: 'หมายเหตุ', value: 'product_amount_comment'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment: ''
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment: ''
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getProductWornOut()
      },
    },
    search: {
      handler() {
        this.getProductWornOut()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    const date = new Date();
    let day = date.getDate();
    let last_month = date.getMonth();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    this.date.push(`${year}-${String(last_month).padStart(2, '0')}-${day}`);
    this.date.push(`${year}-${String(month).padStart(2, '0')}-${day}`);
    this.getProductWornOut()

  },

  methods: {
    exportFile(step){

      if (step == 1){
        var url = `/export_product_worn_out?search=${this.search}&date=${this.date}&type=${this.type_product}&is_worn_out=${this.is_worn_out}`;
        window.open(url,"_blank",'noreferrer')
      }else{
        var url = `${process.env.VUE_APP_BASEURL}/exports_product_worn_out_csv?search=${this.search}&date=${this.date}&type=${this.type_product}&is_worn_out=${this.is_worn_out}`;
        window.open(url,"_blank",'noreferrer')


      }
    },
    editItem(item) {
      this.editedIndex = this.product_worn_outs.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.product_worn_outs.indexOf(item)
      this.editedItem = {...item}
      Swal.fire({
        title: 'ยืนยันการลบข้อมูล ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        buttons: ["ยกเลิก", "ยืนยัน"],
        dangerMode: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.state.isLoading = true
          instance_product_worn_out.productWornOutDestroy(this.editedItem, this.type_product).then(res => {
            this.$store.state.isLoading = false
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);

            this.getProductWornOut()
          }).catch(err => {
            this.$store.state.isLoading = false
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500
            })

          })

        } else {
          // Swal.fire({
          //   icon: 'warning',
          //   title: 'แน่ใจเเล้วสามารถยืนยันการสั่งซื้อได้ทันที',
          //   showConfirmButton: false,
          //   timer: 1500
          // })
        }
      })
    },

    deleteItemConfirm() {

    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    save() {
      // if (this.editedIndex > -1) {
      //   instance_product.employeeTypeUpdate(this.editedItem)
      //     .then(res => {
      //       Object.assign(this.products[this.editedIndex], this.editedItem)
      //     }).catch(err => {
      //
      //   });
      // } else {
      //   instance_product.employeeTypeStore(this.editedItem)
      //     .then(res => {
      //       this.products.push(this.editedItem)
      //     }).catch(err => {
      //
      //   });
      // }
      // this.close()
    },
    getProductWornOut() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        date: this.date,
        type: this.type_product,
        is_worn_out: this.is_worn_out,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }

      instance_product_worn_out.getProductWornOut(data).then(res => {
        this.product_worn_outs = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}


/*.wapper_content1 {*/
/*  overflow-x: hidden;*/
/*  overflow-y: auto;*/
/*  max-height: 23rem;*/
/*  !* background-color: #f5fffa; *!*/
/*}*/
/*.wapperContentBottom {*/
/*  position: relative;*/
/*}*/
/*.wapperIconContent {*/
/*  transform: translateY(-100%);*/
/*  left: 32%;*/
/*  position: absolute;*/
/*}*/


</style>
